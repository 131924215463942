import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"

import { StaticImage } from "gatsby-plugin-image"
import Button from "../button/button"
import Tag from "../tag/tag"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import CountUpAnimationWrapper from "../countup/countup"
import homefoundericon from "../../images/homefoundericon.svg"

import "./calculator.scss"

type CalculatorProps = {

};
type CalculatorState = {
    house_market_value: string;
    interest_rate: string;
    downpayment: string;
    discount: number;
};

class Calculator extends React.Component<CalculatorProps, CalculatorState> {
    state: CalculatorState = {
      house_market_value: "$400,000",
      interest_rate: "6.8%",
      downpayment: "$60,000",
      discount: 15
    };

    strip_non_digits(val : string) : string {
      return val.replace(/[^0-9.]/g,'')
    }

    house_market_value() : number {
      return parseInt(this.strip_non_digits(this.state.house_market_value))
    }

    interest_rate() : number {
      return parseFloat(this.strip_non_digits(this.state.interest_rate))
    }

    downpayment() : number {
      return parseInt(this.strip_non_digits(this.state.downpayment))
    }

    currency(number: number) : string {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(number)
    }

    marketPriceMonthlyPayment() : number {
      let monthlyRate = (this.interest_rate() / 100) / 12
      let principal = this.house_market_value() - this.downpayment()
      let n = 360
      let r = monthlyRate
      let monthlyPayment = principal * ((r*Math.pow(1+r, n))/(Math.pow(1+r, n)-1))
      return monthlyPayment
    }

    marketPriceMonthlyPaymentString() : string {
      return this.currency(this.marketPriceMonthlyPayment()) + "/mo"
    }

    discountPriceTotal() : number {
      return this.house_market_value()*(1.0-(this.state.discount/100.0))
    }

    discountMonthlyPayment() : number{
      let discountPrice = this.house_market_value()*(1.0-(this.state.discount/100.0))
      let monthlyRate = (this.interest_rate() / 100) / 12
      let principal = discountPrice - this.downpayment()
      let n = 360
      let r = monthlyRate
      let monthlyPayment = principal * ((r*Math.pow(1+r, n))/(Math.pow(1+r, n)-1))
      return monthlyPayment
    }

    discountMonthlyPaymentString() : string{
      return this.currency(this.discountMonthlyPayment()) + "/mo"
    }

    lifetimeSavings() : number {
      let r = (this.interest_rate() / 100) / 12
      let P1 = this.house_market_value() - this.downpayment()
      let P2 = (this.house_market_value()*0.85) - this.downpayment()
      let n = 360

      let marketCost = ((r*P1)/(1-Math.pow(1+r, -n))) * n
      let discountCost = ((r*P2)/(1-Math.pow(1+r, -n))) * n

      return marketCost - discountCost
    }

    render() {
      return (
        <div id="calculator" className="savingscalculator section">
          <div className="container">
            <Tag type="yellow">{strings.savings_calculator}</Tag> 
            <h2>{strings.your_savings_with_homefounder}</h2>
            <div className="calculator">
              <div className="input">
                <div className="col">
                  <label className="body2">{strings.house_market_value}</label>
                  <input type="text" placeholder={this.currency(this.house_market_value())} value={this.state.house_market_value} onChange={(event)=> {this.setState({house_market_value: event.target.value})}} />
                </div>
                <div className="col">
                  <label className="body2">{strings.interest_rate}</label>
                  <input type="text" placeholder={this.interest_rate() + "%"} value={this.state.interest_rate} onChange={(event)=> {this.setState({interest_rate: event.target.value})}}/>
                </div>
                <div className="col">
                  <label className="body2">{strings.downpayment}</label>
                  <input type="text" placeholder={this.currency(this.downpayment())} value={this.state.downpayment} onChange={(event)=> {this.setState({downpayment: event.target.value})}} />
                </div>
              </div>
              <div className="divider"></div>
              <div className="totals">
                <div className="market-price">
                  <div className="h3 total">{this.currency(this.house_market_value())}</div>
                  <h4 className="monthly">{this.marketPriceMonthlyPaymentString()}</h4>
                </div>
                <div className="circle"><img src={homefoundericon}/></div>
                <div className="discount-price">
                  <div className="h3 total"><CountUpAnimationWrapper>{this.discountPriceTotal()}</CountUpAnimationWrapper></div>
                  <h4 className="monthly"><CountUpAnimationWrapper>{this.discountMonthlyPayment()}</CountUpAnimationWrapper></h4>
                </div>
              </div>
              <div className="summary">
                  <div className="bonus">
                    <h3><CountUpAnimationWrapper>{this.house_market_value()*(this.state.discount/100.0)}</CountUpAnimationWrapper></h3>
                    <div className="body2">{strings.bonus_homefounder_equity}</div>
                  </div>
                  <div className="divider"></div>
                  <div className="diff">
                    <h3><CountUpAnimationWrapper>{this.marketPriceMonthlyPayment()-this.discountMonthlyPayment()}</CountUpAnimationWrapper></h3>
                    <div className="body2">{strings.monthly_savings}</div>
                  </div>
                  <div className="divider"></div>
                  <div className="lifetime">
                    <h3><CountUpAnimationWrapper>{this.lifetimeSavings()}</CountUpAnimationWrapper></h3>
                    <div className="body2">{strings.total_lifetime_savings}</div>
                  </div>
              </div>
              <AnchorLink className="body1 link" to="/#faq">{strings.learn_more_equity}</AnchorLink>
            </div>
          </div>      
        </div>
      )
    }
}

export default Calculator
