import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"

import { StaticImage } from "gatsby-plugin-image"
import Button from "../button/button"
import Tag from "../tag/tag"
import LocationModal from "../locationmodal/locationmodal"
import arrow from "../../images/timelineArrow.svg"

import LocationCard from "../locationcard/locationcard"

import "./comingsoon.scss"

type ComingSoonProps = {

};
type ComingSoonState = {
  modalOpen: boolean;
  modalTitle: string;
  modalLabel: string;
  modalImage: any;
};

class ComingSoon extends React.Component<ComingSoonProps, ComingSoonState> {
  state: ComingSoonState = {
    modalOpen: false,
    modalTitle: "",
    modalLabel: "",
    modalImage: null,
  };

  freezeBody(){
    document.body.style.position = 'fixed';
    document.body.style.width='100%'
    document.body.style.top = `-${window.scrollY}px`;
  }

  unfreezeBody(){
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width='auto'
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  render(){
    var modal = []
    if (this.state.modalOpen){
      modal.push(<LocationModal title={this.state.modalTitle} label={this.state.modalLabel} image={this.state.modalImage} onLocationModalAction={() => {this.unfreezeBody(); this.setState({modalOpen:false}); navigate("/access")}} onLocationModalClose={() => {this.unfreezeBody(); this.setState({modalOpen:false})}}/>)
    }
  return (
    <>
    <div id="comingsoon" className="comingsoon section">
      <div className="container ">
        <div className="top">
          <Tag type="yellow">{strings.coming_soon}</Tag> 
          <h2>{strings.get_early_access_to_reservations}</h2>
          <div className="body1">{strings.were_exploring_options}</div>
        </div>
        <div className="cards"> 
          <LocationCard onClick={() => {this.freezeBody(); this.setState({modalOpen: true, modalTitle: strings.get_early_access_to_homes_coming_soon1, modalLabel: strings.example_build_models_from_partners_atlanta, modalImage: (<StaticImage alt="Image" src="../../images/modalcollage3.png" />)})}} image={<StaticImage src="../../images/Location1.jpg" alt="Location"/>} discount={strings.comingsoon_discount1} location={strings.comingsoon_location1}/>
          <LocationCard onClick={() => {this.freezeBody(); this.setState({modalOpen: true, modalTitle: strings.get_early_access_to_homes_coming_soon2, modalLabel: strings.example_build_models_from_partners_atlanta, modalImage: (<StaticImage alt="Image" src="../../images/modalcollage.png" />)})}} image={<StaticImage src="../../images/Location2.jpg" alt="Location"/>} discount={strings.comingsoon_discount2} location={strings.comingsoon_location2}/>
          <LocationCard onClick={() => {this.freezeBody(); this.setState({modalOpen: true, modalTitle: strings.get_early_access_to_homes, modalLabel: strings.example_build_models_from_partners_atlanta, modalImage: (<StaticImage alt="Image" src="../../images/modalcollage.png" />)})}} location={strings.atlanta}/>
        </div>
        <div className="timeline">
          <div className="time">
            <div className="body2">{strings.reservations}</div>
            <div className="body2 bold">{strings.reservations_date}</div>
          </div>
          <img src={arrow}/>
          <div className="time">
            <div className="body2">{strings.move_in}</div>
            <div className="body2 bold">{strings.move_in_date}</div>
          </div>
          <div className="divider"></div>
          <div className="body2 slots">{strings.formatString(strings.reservation_slots, strings.reservation_slots_number)}</div>
        </div>
        <Button path="/access" size="large">{strings.cta_button_primary}</Button>
      </div>       
    </div>
    {modal}
    </>
  )
  }
}

export default ComingSoon
