import * as React from "react"
import { Link, HeadFC } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import pin from "../../images/pin.svg"

import "./locationcard.scss"
import strings from "../../i18n"

const LocationCard = ({location, image, discount, onClick, id}) => {
  var className = "location-card"
  var items = []
  if (image){
    className += " image"
    items.push(<div key="overlay" className="overlay"></div>)
    items.push(<div key="image" className="image">{image}</div>)
  }else {
    items.push(<div key="locationcardlabel" className="label body1">{strings.more_neighborhoods_coming_soon}</div>)
    items.push(<img key="locationcardimg" src={pin} className="pin"/>)
  }
  if(discount){
    items.push(<div key="locationcarddiscount" className="discount">{discount}</div>)
  }
  if(location){
    items.push(<div key="locationcardbody" className="location body2">{location}</div>)
  }
  return (
    <div id={id} className={className} onClick={onClick}>
      {items}
    </div>
  )
}

export default LocationCard
