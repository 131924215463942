import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"

import Button from "../button/button"
import Tag from "../tag/tag"
import { StaticImage } from "gatsby-plugin-image"
import LocationModal from "../locationmodal/locationmodal"

import checkmark from "../../images/checkmark2.svg"
import "./models.scss"

type ModelsProps = {

};
type ModelsState = {
  modalOpen: boolean;
};

class Models extends React.Component<ModelsProps, ModelsState> {
  scrollListener: any;

  state: ModelsState = {
    modalOpen: false
  };

  componentDidMount(): void {
    this.scrollListener = this.onScroll.bind(this)
    window.addEventListener('scroll', this.scrollListener)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.scrollListener)
  }

  onScroll(e){
    const elements = document.querySelectorAll(".models .images");
    if (elements.length==0){
      return;
    }
    const a = elements[0]
    const position = a.offsetTop + 64 - window.innerHeight
    if (window.pageYOffset >= position){
      const diff = window.pageYOffset - position
      var percentage  = diff / a.clientHeight
      if (percentage > 1.0){
        return;
      }
      for (const child of a.children) {
        if (child.classList.contains("image-wrapper-1")){
          const val = Math.floor(-14 * percentage)
          const h = 150 * (1-percentage)
          child.style.transform = "translateX("+h+"%) rotate("+val+"deg)"
        }
        if (child.classList.contains("image-wrapper-2")){
          const val = Math.floor(14 * percentage)
          const h = -150 * (1-percentage)
          child.style.transform = "translateX("+h+"%) rotate("+val+"deg)"
        }
        if (child.classList.contains("image-wrapper-3")){
          const val = Math.floor(-7 * percentage)
          const h = 120 * (1-percentage)
          child.style.transform = "translateX("+h+"%) rotate("+val+"deg)"
        }
        if (child.classList.contains("image-wrapper-4")){
          const val = Math.floor(7 * percentage)
          const h = -120 * (1-percentage)
          child.style.transform = "translateX("+h+"%) rotate("+val+"deg)"
        }
      }
    }
  }

  freezeBody(){
    document.body.style.position = 'fixed';
    document.body.style.width='100%'
    document.body.style.top = `-${window.scrollY}px`;
  }

  unfreezeBody(){
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width='auto'
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
  
  render() {
    var modal = []
    if (this.state.modalOpen){
      modal.push(<LocationModal title={strings.get_early_access_to_homes} label={strings.example_build_models_from_partners} image={(<StaticImage src="../../images/modalcollage2.png" />)} onLocationModalAction={() => {this.unfreezeBody(); this.setState({modalOpen:false}); navigate("/access")}} onLocationModalClose={() => {this.unfreezeBody(); this.setState({modalOpen:false})}}/>)
    }
    return (
      <>
      <div className="models section" onScroll={this.onScroll.bind(this)}>
        <div className="container" onClick={() => {this.freezeBody(); this.setState({modalOpen: true})}}>
          <div className="images" >
            <div className="image-wrapper image-wrapper-1">
            <StaticImage src="../../images/model3.jpg" alt="Location" className="image image-1" objectFit="contain"/>
            </div>
            <div className="image-wrapper image-wrapper-2">
            <StaticImage src="../../images/model5.jpg" alt="Location" className="image image-2" objectFit="contain"/>
            </div>
            <div className="image-wrapper image-wrapper-3">
            <StaticImage src="../../images/model2.jpg" alt="Location" className="image image-3" objectFit="contain"/>
            </div>
            <div className="image-wrapper image-wrapper-4">
            <StaticImage src="../../images/model4.jpg" alt="Location" className="image image-4" objectFit="contain"/>
            </div>
            <div className="image-wrapper image-wrapper-5">
            <StaticImage src="../../images/model1.jpg" alt="Location" className="image image-5" objectFit="contain"/>
            </div>
          </div>
          <div className="images-mobile">
            <StaticImage src="../../images/mobile-images.png" alt="Home models"/>
          </div>
          <div className="points horizontal-padding">
            <div className="point">
              <img src={checkmark}/>
              <h4 className="title">{strings.models_point1}</h4>
              <div className="body2">{strings.models_point1_description}</div>
            </div>
            <div className="point">
              <img src={checkmark}/>
              <h4 className="title">{strings.models_point2}</h4>
              <div className="body2">{strings.models_point2_description}</div>
            </div>
            <div className="point">
              <img src={checkmark}/>
              <h4 className="title">{strings.models_point3}</h4>
              <div className="body2">{strings.models_point3_description}</div>
            </div>
          </div>
          <div className="body2">{strings.example_build_models_from_partners}</div>
        </div>       
      </div>
      {modal}
      </>
    )
  }
}

export default Models
