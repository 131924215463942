import * as React from "react"
import { Link, HeadFC } from "gatsby"
import Layout from "../components/layout/layout"
import CTA from "../components/cta/cta"
import Hero from "../components/hero/hero"
import TeamThatCares from "../components/teamthatcares/teamthatcares"
import ComingSoon from "../components/comingsoon/comingsoon"
import FAQSection from "../components/faqsection/faqsection"
import Savings from "../components/savings/savings"
import Calculator from "../components/calculator/calculator"
import NewApproach from "../components/newapproach/newapproach"
import Models from "../components/models/models"
import strings from "../i18n"
import SEO from "../components/seo/seo"

const IndexPage = () => {
  return (
    <Layout chat={true}>
        <Hero/>
        <NewApproach/>
        <Calculator/>
        <Savings/>
        <Models/>
        <TeamThatCares/>
        <ComingSoon/>
        <FAQSection/>
        <CTA 
          primary={strings.cta_primary}
          secondary={strings.cta_secondary}
          description={strings.formatString(strings.cta_primary_description, strings.reservation_slots_number)}
          button={strings.cta_button_primary}
          to="/access"
        />
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <SEO/>
