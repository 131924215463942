import * as React from "react"
import { Link, HeadFC } from "gatsby"

import Button from "../button/button"
import "./faqsection.scss"
import strings from "../../i18n"

import plus from "../../images/plusicon.svg"
import { StaticImage } from "gatsby-plugin-image"

import phone from "../../images/phone.svg"
import email from "../../images/email.svg"
import chat from "../../images/chat.svg"
import ReactMarkdown from 'react-markdown'

type FAQSectionProps = {
};
type FAQSectionState = {
    isOpen: boolean;
    mounted: boolean;
};

class FAQSection extends React.Component<FAQSectionProps, FAQSectionState> {
    state: FAQSectionState = {
      isOpen: false,
      mounted: false
    };

    componentDidMount() {
      this.setState({mounted: true})
    }

  render(){
  return (
      <div id="faq" className="faqsection horizontal-padding section">
        <div className="container section">
          <h2>{strings.frequently_asked_questions}</h2>
          <div className="cols">
            <div className="questions">
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question1}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion1" className="body1 answer"><ReactMarkdown>{strings.faq_answer1}</ReactMarkdown></div>
              </div>
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question2}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion2" className="body1 answer"><ReactMarkdown>{strings.faq_answer2}</ReactMarkdown></div>
              </div>
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question3}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion3" className="body1 answer"><ReactMarkdown>{strings.faq_answer3}</ReactMarkdown></div>
              </div>
              <div className="view-more">
                <Link to="/faq" className="body1">{strings.view_more_faq} <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5 -8.74228e-07C16.0228 -3.91405e-07 20.5 4.47715 20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.499998 15.5228 0.499999 10C0.506061 4.47966 4.97967 0.0060602 10.5 -8.74228e-07ZM10.5 18C14.8946 17.9989 18.4655 14.4531 18.4978 10.0587C18.53 5.66417 15.0114 2.06644 10.6173 2.00089C6.22319 1.93534 2.59888 5.42651 2.5 9.82L2.5 8.037L2.5 10C2.50496 14.4162 6.08378 17.995 10.5 18ZM10.5 5L15.5 10L10.5 15L9.09 13.59L11.67 11L5.5 11L5.5 9L11.67 9L9.09 6.41L10.5 5Z" fill="#264E70"/>
</svg></Link>
              </div>
            </div>
            <div className="more-questions">
              <div className="info">
              <div className="copy">
              <h4>{strings.more_questions}</h4>
              <div className="body1">{strings.get_your_questions_answered}</div>
              </div>
              <StaticImage objectFit="contain" src="../../images/HomeSpecialist.png" className="home-specialist" alt={strings.get_your_questions_answered}/>
              </div>
              <div className="buttons">
              <Button url="https://calendar.app.google/cLEfkPPcUv3pu1228" target="_blank" size="small" iconFirst={true}><img src={phone}/>{strings.schedule_call}</Button>
              <Button onClick={() => {LO.messenger.open()}} size="small" iconFirst={true} outline={true}><img src={chat}/>{strings.chat}</Button>
              <Button path="/contact" size="small" outline={true} iconFirst={true}><img src={email}/>{strings.email}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
  }
}

export default FAQSection
