import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"

import Button from "../button/button"
import Tag from "../tag/tag"
import checkmark from "../../images/checkmark2.svg"

import "./teamthatcares.scss"

const TeamThatCares = () => {
  return (
    <div id="teamthatcares" className="teamthatcares section">
      <div className="container">
        <Tag>{strings.a_team_that_cares}</Tag> 
        <h2>{strings.weve_got_your_back}</h2>
        <div className="points">
          <div className="point">
            <img src={checkmark}/>
            <div className="h4 title">{strings.cares_point1}</div>
            <div className="body2">{strings.cares_point1_description}</div>
          </div>
          <div className="point">
            <img src={checkmark}/>
            <div className="h4 title">{strings.cares_point2}</div>
            <div className="body2">{strings.cares_point2_description}</div>
          </div>
          <div className="point">
            <img src={checkmark}/>
            <div className="h4 title">{strings.cares_point3}</div>
            <div className="body2">{strings.cares_point3_description}</div>
          </div>
          <div className="point">
            <img src={checkmark}/>
            <div className="h4 title">{strings.cares_point4}</div>
            <div className="body2">{strings.cares_point4_description}</div>
          </div>
        </div>
      </div>       
    </div>
  )
}

export default TeamThatCares
