import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"

import { StaticImage } from "gatsby-plugin-image"
import Button from "../button/button"
import Tag from "../tag/tag"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import "./newapproach.scss"

import approach1 from "../../images/approach1.svg"
import approach2 from "../../images/approach2.svg"
import approach3 from "../../images/approach3.svg"
import approach4 from "../../images/approach4.svg"

const NewApproach = () => {
  return (
    <div className="newapproach horizontal-padding section">
      <div className="container section">
        <div className="top">
          <Tag type="yellow">{strings.new_approach_to_new_construction}</Tag> 
          <h2>{strings.new_construction_with_meaningful_savings}</h2>
          <div className="body1">{strings.new_approach_description}</div>
        </div>
        <div className="steps">
          <div className="step">
            <img src={approach1} />
            <div className="info">
            <h4>{strings.approach_step_1}</h4>
            <div className="body2">{strings.approach_step_1_description}</div>
            <AnchorLink className="body1 link" to="/#calculator">{strings.savings_calculator}</AnchorLink>
            </div>
          </div>
          <div className="step">
            <img src={approach2} />
            <div className="info">
            <h4>{strings.approach_step_2}</h4>
            <div className="body2">{strings.approach_step_2_description}</div>
            <AnchorLink className="body1 link" to="/#hiw">{strings.learn_more}</AnchorLink>
            </div>
          </div>
          <div className="step">
            <img src={approach3} />
            <div className="info">
            <h4>{strings.approach_step_3}</h4>
            <div className="body2">{strings.approach_step_3_description}</div>
            <AnchorLink className="body1 link" to="/#hiw">{strings.how_it_works}</AnchorLink>
            </div>
          </div>
          <div className="step">
            <img src={approach4} />
            <div className="info">
            <h4>{strings.approach_step_4}</h4>
            <div className="body2">{strings.approach_step_4_description}</div>
            <AnchorLink className="body1 link" to="/#teamthatcares">{strings.learn_more}</AnchorLink>
            </div>
          </div>
        </div>
      </div>       
    </div>
  )
}

export default NewApproach
