import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"

import { StaticImage } from "gatsby-plugin-image"
import Button from "../button/button"
import Tag from "../tag/tag"

import "./savings.scss"

const Savings = () => {
  return (
    <div id="hiw" className="savings section">
      <div className="container">
        <div className="top">
          <Tag type="yellow">{strings.how_it_works}</Tag> 
          <h2>{strings.months_to_smart_savings}</h2>
          <div className="body1">{strings.maximize_your_savings}</div>
        </div>
        <div className="steps">
          <div className="step">
            <div className="info">
            <div className="body1 title">{strings.step} 1</div>
            <h4>{strings.savings_step_1}</h4>
            </div>
            <StaticImage src="../../images/step1.png" className="image" alt={strings.savings_step_1}/>
          </div>
          <div className="step">
            <div className="info">
            <div className="body1 title">{strings.step} 2</div>
            <h4>{strings.savings_step_2}</h4>
            </div>
            <StaticImage src="../../images/step2.png" className="image" alt={strings.savings_step_2}/>
          </div>
          <div className="step">
            <div className="info">
            <div className="body1 title">{strings.step} 3</div>
            <h4>{strings.savings_step_3}</h4>
            </div>
            <StaticImage src="../../images/step3a.png" className="image" alt={strings.savings_step_3}/>
          </div>
          <div className="step">
            <div className="info">
            <div className="body1 title">{strings.step} 4</div>
            <h4>{strings.savings_step_4}</h4>
            </div>
            <StaticImage src="../../images/step4.png" className="image" alt={strings.savings_step_4}/>
          </div>
        </div>
        <Button path="/access" size="medium" outline={true}>{strings.learn_more}</Button>
      </div>       
    </div>
  )
}

export default Savings
