import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"
import LocationModal from "../locationmodal/locationmodal"
import { navigate } from 'gatsby';
import Tag from "../tag/tag";
import Button from "../button/button"
import LocationCard from "../locationcard/locationcard";
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "./hero.scss"

type HeroProps = {
};
type HeroState = {
  modalOpen: boolean;
  modalTitle: string;
  modalLabel: string;
  modalImage: any;
};

class Hero extends React.Component<HeroProps, HeroState> {
  state: HeroState = {
    modalOpen: false,
    modalTitle: "",
    modalLabel: "",
    modalImage: null,
  };

  freezeBody(){
    document.body.style.position = 'fixed';
    document.body.style.width='100%'
    document.body.style.top = `-${window.scrollY}px`;
  }

  unfreezeBody(){
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width='auto'
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  render(){
    var modal = []
  if (this.state.modalOpen){
    modal.push(<LocationModal key="locationmodal" title={this.state.modalTitle} label={this.state.modalLabel} image={this.state.modalImage}  onLocationModalAction={() => {this.unfreezeBody(); this.setState({modalOpen:false}); navigate("/access")}} onLocationModalClose={() => {this.unfreezeBody(); this.setState({modalOpen:false})}}/>)
  }
  return (
    <>
    <div className="hero section">
        <div className="container">
            <div className="left">
                <Tag type="creme no-icon">{strings.waiting_for_home_prices_to_drop}</Tag>
                <h1>{strings.new_construction_with_pre_purchase}</h1>
                {/* <h4>{strings.prices_you_want_already_here}</h4> */}
                <Button path="/access" size="large" size="extra-large">{strings.cta_button_primary}</Button>
                <div className="body2"><AnchorLink className="location" to="/#comingsoon">{strings.atlanta}</AnchorLink><span className="reservations">{strings.formatString(strings.hero_reservations, strings.reservation_slots_number)}</span></div>
            </div>
            <div className="right">
                <div className="outerCircle"><div className="innerCircle"></div></div>
                <LocationCard id="heroLocation1" onClick={() => {this.freezeBody(); this.setState({modalOpen: true, modalTitle: strings.get_early_access_to_homes_coming_soon1, modalLabel: strings.example_build_models_from_partners_atlanta, modalImage: (<StaticImage alt="Image" src="../../images/modalcollage2.png" />)})}} image={<StaticImage src="../../images/heroHouse2.jpg" alt="Location"/>} discount={strings.comingsoon_discount2} location={strings.comingsoon_location1}/>
                <LocationCard id="heroLocation2" onClick={() => {this.freezeBody(); this.setState({modalOpen: true, modalTitle: strings.get_early_access_to_homes_coming_soon2, modalLabel: strings.example_build_models_from_partners_atlanta, modalImage: (<StaticImage alt="Image" src="../../images/modalcollage2.png" />)})}} image={<StaticImage src="../../images/heroHouse1.jpg" alt="Location"/>} discount={strings.comingsoon_discount1} location={strings.comingsoon_location2}/>
                <div className="body3">{strings.example_home_models}</div>
            </div>
        </div>
    </div>
    {modal}
    </>
  )
  }
}

export default Hero
